<template>
	<div>
		<div class="good_add good_addshoped">
			<header>
				<span class="back" @click="headback()">
					<i class="el-icon-arrow-left" style="font-size: 14px"></i>
					返回
					<i class="line">|</i>
				</span>
				<span class="titile">{{ id == 0 ? "创建商品" : "编辑商品" }}</span>
			</header>
			<nav>
				<div class="addition-type">
					<div class="tab-title">
						<span>选择商品类型</span>
						<!-- <span>商品类型，商品保存后无法修改，请谨慎选择</span> -->
					</div>
					<div class="tab-content">
						<div class="tab_wrap">
							<div class="tab-item" :class="[tabIndex == 0 ? 'active' : '']" @click="typeTab(0)">
								<p>普通商品</p>
								<p>物流发货</p>
							</div>
							<!-- <div class="tab-item" :class="[tabIndex == 1 ? 'active' : '']" @click="typeTab(1)">
								<p>虚拟商品</p>
								<p>无需物流</p>
							</div> -->
							<!-- <div class="tab-item" :class="[tabIndex == 3?'active':'']" @click="typeTab(3)">
								<p>卡密商品</p>
								<p>无需物流</p>
							</div> -->
						</div>
					</div>
				</div>
				<div class="addtion_wrap">
					<div class="addtion_tab">
						<div class="good-tab-item" :class="[goodIndex == 1 ? 'active' : '']" @click="goodTab(1)">
							基础信息
						</div>
						<div class="good-tab-item" :class="[goodIndex == 2 ? 'active' : '']" @click="goodTab(2)">
							价格/库存
						</div>
						<div class="good-tab-item" :class="[goodIndex == 3 ? 'active' : '']" @click="goodTab(3)">
							商品参数
						</div>
						<!-- <div class="good-tab-item" :class="[goodIndex == 4 ? 'active' : '']" @click="goodTab(4)">
							物流设置
						</div> -->
						<div class="good-tab-item" :class="[goodIndex == 5 ? 'active' : '']" @click="goodTab(5)">
							商品详情
						</div>
						<!-- 暂时隐藏误删 -->
						<!-- <div class="good-tab-item" :class="[goodIndex==6?'active':'']" @click="goodTab(6)">
							优惠活动</div> -->
						<!-- <div class="good-tab-item" :class="[goodIndex==7?'active':'']" @click="goodTab(7)">
							会员价设置</div> -->
						<!-- 暂时隐藏 误删 -->
						<!-- <div class="good-tab-item" :class="[goodIndex==8?'active':'']" @click="goodTab(8)">
							商品推广</div> -->
						<div class="good-tab-item" :class="[goodIndex == 9 ? 'active' : '']" @click="goodTab(9)">
							SEO设置
						</div>
					</div>
					<div class="addtion_container">
						<template v-if="goodIndex == 1">
							<div class="essential">
								<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
									<el-form-item label="商品分类" prop="classify">
										<el-cascader class="elcascaders" :append-to-body="false" placeholder="请选择商品分类"
											filterable clearable v-model="ruleForm.classify" :options="ruleForm.options"
											:props="{ value: 'id', label: 'name', level: 'level', children: 'children', }"
											@change="shopfenlei($event)"></el-cascader>
									</el-form-item>
									<div class="good_headline">
										<el-form-item label="商品名称" prop="goodTiltle">
											<el-input v-model="ruleForm.goodTiltle" type="textarea" resize="none"
												placeholder="请输入商品名称" maxlength="255" show-word-limit>
											</el-input>
										</el-form-item>
									</div>
									<div class="subhead">
										<el-form-item label="副标题" prop="subheading">
											<el-input v-model="ruleForm.subheading" type="textarea" resize="none"
												placeholder="请输入副标题" maxlength="255" show-word-limit>
											</el-input>
										</el-form-item>
									</div>
									<div class="huohao">
										<el-form-item label="主商品库货号" prop="commodity_artNo">
											<el-input v-model="ruleForm.commodity_artNo" disabled> </el-input>
										</el-form-item>
									</div>
									<div class="huohao">
										<el-form-item label="商品货号" prop="artNo">
											<el-input v-model="ruleForm.artNo"> </el-input>
										</el-form-item>
									</div>
									<div class="thumbnail">
										<el-form-item label="商品缩略图" prop="cover_pic">
											<div class="d-flex align-items-center">
												<div class="skeletonizecon" @click="photoVisible(0)">
													<img v-if="ruleForm.cover_pic" :src="ruleForm.cover_pic" alt=""
														:onerror="errorImg" />
													<i class="el-icon-plus" v-else></i>
												</div>
											</div>
											<div class="skeletonize_toast">图片尺寸建议325*325</div>
										</el-form-item>
									</div>
									<!-- 暂时隐藏误删 -->
									<div class="goods_video" style="display: none">
										<el-form-item label="商品视频" prop="video">
											<div class="d-flex align-items-center">
												<div class="skeletonizecon" @click="videoVisible()">
													<i class="el-icon-plus"></i>
												</div>
											</div>
											<div class="skeletonize_toast">
												支持格式mp4;支持编码H.264;视频大小不能超过50MB
											</div>
										</el-form-item>
									</div>

									<div class="goods_imger">
										<el-form-item label="商品图片" prop="goods_pics">
											<div class="d-flex align-items-center">
												<div class="skeletonizeconed">
													<div class="sklist clearfloat">
														<div v-for="(item, index) in ruleForm.goods_pics" :key="index"
															class="sklistimgs" draggable="true"
															@dragstart="handleDragStart($event, item)"
															@dragover.prevent="handleDragOver($event, item)"
															@dragenter="handleDragEnter($event, item)"
															@dragend="handleDragEnd($event, item)">
															<img :src="item.file_url" alt="" :onerror="errorImg" />
															<span @click="delectimg(index)"
																class="el-icon-circle-close skclose"></span>
															<span class="setbanner"
																@click="setbannerimg(item.file_url)"><strong>设置商品主图</strong></span>
														</div>
														<div class="skduoxuan" @click="photoVisible(1)">
															<i class="el-icon-plus"></i>
														</div>
													</div>
												</div>
											</div>
											<div class="skeletonize_toast">图片尺寸建议750*750</div>
										</el-form-item>
									</div>
									<div class="goods_brand">
										<el-form-item label="商品品牌" prop="brand">
											<!-- <el-select :popper-append-to-body="false" v-model="ruleForm.brand"
												filterable clearable placeholder="请选择">
												<el-option v-for="item in brandOptions" :key="item.id"
													:label="item.name" :value="item.id">
												</el-option>
											</el-select> -->
											<el-select v-model="ruleForm.brand" filterable placeholder="请选择" clearable
												remote :remote-method="remoteMethoded" @visible-change="templateTagChange">
												<el-option v-for="item in brandOptions" :key="item.id" :label="item.name"
													:value="item.id">
												</el-option>
												<template>
													<div class="purpagebutkong"></div>
													<div class="purpagebut">
														<el-pagination :small="true" @current-change="handclassfly"
															hide-on-single-page :current-page="brandPage" :page-size="10"
															layout="prev, next" :total="brandtotal_entry">
														</el-pagination>
													</div>
												</template>
											</el-select>
										</el-form-item>
									</div>
									<div class="goods_brand">
										<el-form-item label="服务内容" prop="servicecontent">
											<el-select :popper-append-to-body="false" v-model="ruleForm.servicecontent"
												multiple filterable clearable placeholder="请选择服务内容">
												<el-option v-for="item in serviceconlist" :key="item.id" :label="item.name"
													:value="item.id">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
									<div class="goods_brand">
										<el-form-item label="商品标签" prop="tally">
											<el-select :popper-append-to-body="false" v-model="ruleForm.tally" multiple
												filterable clearable placeholder="请选择商品标签">
												<el-option v-for="item in tallyOptions" :key="item.id" :label="item.name"
													:value="item.id">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
									<div class="goods_brand">
										<el-form-item label="商品分组" prop="tally">
											<el-select :popper-append-to-body="false" v-model="ruleForm.grouptexted"
												multiple filterable clearable placeholder="请选择商品分组">
												<el-option v-for="item in grouplist" :key="item.id" :label="item.name"
													:value="item.id">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
									<!-- 虚拟商品展示 -->
									<template v-if="tabIndex == 1">
										<el-form-item label="自动发货" prop="zddelivery">
											<el-radio-group v-model="ruleForm.zddelivery">
												<el-radio :label="0">否</el-radio>
												<el-radio :label="1">是</el-radio>
											</el-radio-group>
											<div class="skeletonize_toast">
												商品自动发货仅限虚拟商品,若非固定信息商品请勿自动发货
											</div>
										</el-form-item>
										<el-form-item label="发货内容" prop="deliverycon">
											<el-input v-model="ruleForm.deliverycon" type="textarea" placeholder="请输入发货内容"
												maxlength="255" show-word-limit>
											</el-input>
											<div class="skeletonize_toast">
												商品自动发货仅限虚拟商品,若非固定信息商品请勿自动发货
											</div>
										</el-form-item>
									</template>
									<el-form-item label="商品排序" prop="sort">
										<el-input-number type="number" class="addgoodsnumber" :min="0" :max="100000000"
											v-model="ruleForm.sort" :controls="false">
										</el-input-number>
										<div class="skeletonize_toast">排序按升序排列</div>
									</el-form-item>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 2">
							<div class="essential">
								<el-form :rules="priceRules" ref="priceForm" :model="priceForm" label-width="160px">
									<el-form-item label="商品规格" prop="specifi" v-if="false">
										<div class="">
											<div>
												<div class="specification" v-for="(item, index) in priceForm.specifi"
													:key="index">
													<i class="el-icon-error specification_error"
														@click="delSpec(index)"></i>
													<div class="spec-item d-flex">
														规格名：<el-input maxlength="12" placeholder="如颜色/尺码/套餐"
															v-model="item.specs_key" show-word-limit></el-input>
														规格值：<el-input maxlength="50" placeholder="请输入规格值"
															v-model="item.specs_value" show-word-limit></el-input>
													</div>
												</div>
												<div class="accretionSpect">
													<el-button type="primary" @click="appendSpec">添加规格</el-button>
												</div>
											</div>
										</div>
									</el-form-item>
									<el-form-item label="售卖价格" prop="sellPrice">
										<div class="addgoodsbox clearfloat">
											<el-input-number type="number" :precision="2" class="addgoodsnumber" :min="0"
												placeholder="请输入售卖价格" :max="99999999.99" v-model="priceForm.sellPrice"
												:controls="false">
											</el-input-number>
											<span class="addgoodstext">积分</span>
										</div>
									</el-form-item>
									<el-form-item label="市场价格" prop="lineation">
										<div class="addgoodsbox clearfloat">
											<el-input-number type="number" :precision="2" class="addgoodsnumber" :min="0"
												placeholder="请输入市场价格" :max="99999999.99" v-model="priceForm.lineation"
												:controls="false">
											</el-input-number>
											<span class="addgoodstext">积分</span>
										</div>
										<div class="skeletonize_toast">
											商品没有优惠的情况下，市场价在商品详情会以划线形式显示
										</div>
									</el-form-item>
									<el-form-item label="成本价格" prop="costprice">
										<div class="addgoodsbox clearfloat">
											<el-input-number type="number" :precision="2" class="addgoodsnumber" :min="0"
												placeholder="请输入成本价格" :max="99999999.99" v-model="priceForm.costprice"
												:controls="false">
											</el-input-number>
											<span class="addgoodstext">积分</span>
										</div>
										<div class="skeletonize_toast">
											成本价将不会对用户展示，仅在订单导出时，可导出该价格，便于商家统计使用
										</div>
									</el-form-item>
									<el-form-item label="商品库存" prop="repertory">
										<div class="addgoodsbox clearfloat">
											<el-input-number type="number" class="addgoodsnumber" :min="0" :max="100000000"
												v-model="priceForm.repertory" :controls="false">
											</el-input-number>
											<span class="addgoodstext">件</span>
										</div>
									</el-form-item>
									<!-- <el-form-item label="是否开启面议" prop="discuss">
										<el-radio-group v-model="priceForm.discuss">
											<el-radio :label="0">否</el-radio>
											<el-radio :label="1">是</el-radio>
										</el-radio-group>
										<div class="skeletonize_toast">
											如果开启面议，则商品无法在线支付
										</div>
									</el-form-item> -->
									<el-form-item label="商品单位" prop="unit">
										<el-input v-model="priceForm.unit" type="text" placeholder="请输入商品单位">
										</el-input>
									</el-form-item>
									<el-form-item label="已兑换量" prop="sell_num">
										<el-input-number type="number" class="addgoodsnumber" :min="0" :max="100000000"
											v-model="priceForm.sell_num" :controls="false">
										</el-input-number>
										<div class="skeletonize_toast">
											前端展示的销量=实际销量+已兑换量
										</div>
									</el-form-item>
									<el-form-item label="限购数量" prop="purchase">
										<el-input-number type="number" class="addgoodsnumber" :min="0" :max="100000000"
											v-model="priceForm.purchase" :controls="false">
										</el-input-number>
										<div class="skeletonize_toast">
											设置为0则不限兑换，大于0则等于对应的兑换数量
										</div>
									</el-form-item>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 3">
							<div class="essential">
								<el-form :model="paramForm" ref="paramForm" label-width="160px">
									<div class="goods_brand">
										<el-form-item label="类型">
											<el-radio-group v-model="paramForm.paramType" @change="templateradio">
												<el-radio v-for="(item, index) in common_info['goods.param.type.list']"
													:key="index" :label="item.key">{{ item.value }}</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="规格参数名字" v-if="paramForm.paramType == 1">
											<el-select v-model="paramForm.title" @change="paramchange">
												<el-option v-for="(item, index) in paramForm.partemplate" :key="index"
													:label="item.cat_attr_name" :value="item.id"></el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-form>
								<div class="addtemplate" v-if="paramForm.paramType != 0">
									<div class="addtemplatetitle">编辑参数</div>
									<div class="addtemplatecon">
										<div class="addtemplateleft">
											<template v-if="paramForm.paramType == 1">
												<div class="params_tble">
													<el-table :data="tableData" style="width: 100%">
														<el-table-column label="参数名称" width="150">
															<template slot-scope="scope">
																<div class="params_input" v-if="scope.row.fillType != 3">
																	<p>
																		<i v-if="scope.row.required == 1">*</i>{{
																			scope.row.attr_name }}
																	</p>
																</div>
															</template>
														</el-table-column>
														<el-table-column label="参数类型" width="400">
															<template slot-scope="scope">
																<div class="attrgoods">
																	<div v-if="scope.row.type_desc == 'input'">
																		<el-input v-model="scope.row.default"
																			placeholder="请输入选项值">
																		</el-input>
																		<p v-if="scope.row.tips">
																			提示:{{ scope.row.tips }}
																		</p>
																	</div>
																	<div v-if="scope.row.type_desc == 'select'">
																		<el-select filterable v-model="scope.row.checkouted"
																			@change="mbchanges($event, scope)">
																			<el-option
																				v-for="(item, index) in scope.row.default"
																				:key="index" :label="item"
																				:value="item"></el-option>
																		</el-select>
																		<p v-if="scope.row.tips">
																			提示:{{ scope.row.tips }}
																		</p>
																	</div>
																	<div v-if="scope.row.type_desc == 'multi_select'">
																		<el-select multiple filterable
																			v-model="scope.row.checkouted"
																			@change="mbcheckbox($event, scope)">
																			<el-option
																				v-for="(item, index) in scope.row.default"
																				:key="index" :label="item"
																				:value="item"></el-option>
																		</el-select>
																		<p v-if="scope.row.tips">
																			提示:{{ scope.row.tips }}
																		</p>
																	</div>
																</div>
															</template>
														</el-table-column>

														<template slot="empty">
															<div class="empty_data">
																<p>暂无数据</p>
															</div>
														</template>
													</el-table>
												</div>
											</template>
											<template v-if="paramForm.paramType == 2">
												<div class="params_tble">
													<el-table :data="tableData" style="width: 100%">
														<el-table-column width="60">
															<template slot-scope="scope">
																<div class="params_move">
																	<i></i>
																</div>
															</template>
														</el-table-column>
														<el-table-column label="参数名称" width="150">
															<template slot-scope="scope">
																<div class="params_input" v-if="scope.row.fillType != 3">
																	<el-input placeholder="请输入参数名称"
																		v-model="scope.row.attr_name">
																	</el-input>
																</div>
															</template>
														</el-table-column>
														<el-table-column label="参数类型" width="400">
															<template slot-scope="scope">
																<div class="d-flex align-items-center flex-wrap canshu">
																	<el-input v-model="scope.row.default"
																		placeholder="请输入选项值,选项值请用英文逗号,分割">
																	</el-input>
																</div>
															</template>
														</el-table-column>
														<el-table-column label="操作" width="100" align="center">
															<template slot-scope="scope">
																<div class="caozuo" @click="delparams(scope)">
																	删除
																</div>
															</template>
														</el-table-column>

														<template slot="empty">
															<div class="empty_data">
																<p>暂无数据</p>
															</div>
														</template>
													</el-table>
													<div class="argument_foot">
														<el-button type="primary" @click="addparams">+添加新参数</el-button>
													</div>
												</div>
											</template>
											<template v-if="paramForm.paramType == 3">
												<div class="cseditor">
													<Editor v-model="cseditor" placeholder="请输入内容" height="450"
														@change="handleContentChange" ref="editorcon"
														@uploadImgs="uploadImgs" />
												</div>
											</template>
										</div>
										<div class="addtemplateright">
											<div class="addmobile">
												<div class="addmobiletitle">规格参数</div>
												<div class="addmobilecon" v-if="tableData && tableData.length">
													<div class="addtemplateconter" v-for="(item, index) in tableData"
														:key="index">
														<div class="addtextleft">
															{{ item.fillType != 3 ? item.attr_name : "" }}
														</div>
														<div class="addtextright">
															<div class="addtextrightext" v-if="item.type_desc == 'input'">
																<span v-for="(items, indexs) in item.default"
																	:key="indexs">{{ items }}</span>
															</div>
															<div class="addtextrightext" v-if="item.type_desc == 'select'">
																<span v-if="item.checkouted && item.checkouted.length">{{
																	item.checkouted }}</span>
															</div>
															<div class="addtextrightext"
																v-if="item.type_desc == 'multi_select'">
																<span v-if="item.checkouted && item.checkouted.length">{{
																	item.checkouted.join(" ") }}</span>
															</div>
															<div class="addtextrightext" v-if="!item.type_desc">
																<span>{{ item.default }}</span>
															</div>
														</div>
													</div>
												</div>
												<template v-if="paramForm.paramType == 3">
													<div class="addmobilecon">
														<div v-html="cseditor"></div>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="goodIndex == 4">
							<div class="essential">
								<el-form :rules="logistics" ref="phyForm" :model="phyForm" label-width="160px">
									<div class="matSupport">
										<el-form-item label="物流支持" prop="material">
											<el-checkbox-group v-model="phyForm.material">
												<el-checkbox v-for="(item, index) in common_info['goods.express.type.list']"
													:key="index" :label="item.key">{{ item.value }}</el-checkbox>
											</el-checkbox-group>
										</el-form-item>
									</div>
									<div v-if="phyForm.material.indexOf(1) != -1">
										<el-form-item label="快递运费" prop="express">
											<div class="express_radio">
												<el-radio-group v-model="phyForm.express.unify" @change="agna">
													<div class="express_tem d-flex align-items-start">
														<el-radio :label="2">
															<span>运费模板</span>
														</el-radio>
														<div class="express_unify">
															<el-select v-model="phyForm.express.board"
																:disabled="phyForm.express.unify == 2 ? false : true"
																clearable placeholder="请选择">
																<el-option v-for="(item, index) in yunfeilist"
																	:key="item.id" :label="item.name" :value="item.id">
																</el-option>
															</el-select>
														</div>
													</div>
													<div class="express_tem d-flex align-items-center">
														<el-radio :label="1">
															<span>统一运费</span>
														</el-radio>

														<div class="express_unify">
															<el-input v-model="phyForm.express.price" type="number"
																:disabled="phyForm.express.unify == 1 ? false : true"
																placeholder="请输入价格">
																<template slot="append">积分</template>
															</el-input>
														</div>
													</div>
												</el-radio-group>
											</div>
										</el-form-item>
									</div>
									<!-- 暂时隐藏误删 -->
									<!-- <el-form-item label="商品详情显示快递" prop="is_express">
										<el-radio-group v-model="phyForm.is_express">
											<el-radio :label="1">显示</el-radio>
											<el-radio :label="0">隐藏</el-radio>
										</el-radio-group>
									</el-form-item> -->
									<el-form-item label="商品重量" prop="weight">
										<div class="addgoodsbox clearfloat">
											<el-input-number type="number" class="addgoodsnumberzl" :precision="2" :min="0"
												placeholder="请输入重量" :max="99999999.99" v-model="phyForm.weight"
												:controls="false">
											</el-input-number>
											<span class="addgoodstext">千克</span>
										</div>
									</el-form-item>
									<!-- 暂时隐藏误删 -->
									<!-- <el-form-item label="商品体积" prop="volume"> -->
									<!-- <div class="addgoodsbox clearfloat"> -->
									<!-- <el-input-number type="number" :precision="2" class="addgoodsnumber " min="" placeholder="请输入体积" :max="99999999.99"
												v-model="phyForm.volume" :controls="false">
											</el-input-number> -->
									<!-- <span class="addgoodstext">千克</span> -->
									<!-- </div> -->
									<!-- </el-form-item> -->
								</el-form>
							</div>
						</template>
						<template>
							<!-- 商品详情 -->
							<div class="essential particulars" v-show="goodIndex == 5">
								<el-form label-width="160px" :rules="parformed" ref="parForm" :model="parForm">
									<!-- <div class="goods_brand">
										<el-form-item label="售后保障" prop="sale">
											<el-select :popper-append-to-body="false" v-model="parForm.packaging"
												filterable clearable placeholder="请选择售后保障">
												<el-option v-for="item in packaglist" :key="item.id" :label="item.title"
													:value="item.id">
												</el-option>
											</el-select>
										</el-form-item>
									</div> -->
									<!-- <div class="goods_brand">
										<el-form-item label="包装清单" prop="packaging">
											<el-select :popper-append-to-body="false" v-model="parForm.sale" filterable
												clearable placeholder="请选择包装清单">
												<el-option v-for="item in salelist" :key="item.id" :label="item.title"
													:value="item.id">
												</el-option>
											</el-select>
										</el-form-item>
									</div> -->
									<el-form-item label="商品详情" prop="details">
										<Editor v-model="editorcontent" placeholder="商品详情" height="350" @change="gooddetail"
											@uploadImgs="uploadImgs" />
									</el-form-item>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 6">
							<div class="essential">
								<el-form label-width="160px" :rules="markeRules" ref="markeForm" :model="markeForm">
									<div class="head_title">
										<p>营销</p>
									</div>
									<el-form-item label="积分赠送" prop="presentExp">
										<el-input type="number" placeholder="" v-model="markeForm.presentExp">
											<template slot="append"> 分 </template>
										</el-input>
										<div class="present_tips">
											会员购物赠送的积分,
											如果不填写或填写0，则默认为不赠送积分，如果带%则为按成交价格的比例计算积分
											<br />
											如: 购买2件，设置10 积分, 不管成交价格是多少，
											则购买后获得20积分
											<br />
											如: 购买2件，设置10%积分, 成交价格2 * 200= 400，
											则购买后获得 40 积分（400*10%）
										</div>
									</el-form-item>
									<el-form-item label="积分抵扣" prop="deduction">
										<el-input type="number" placeholder="最多抵扣" v-model="markeForm.deduction">
											<template slot="append"> 元 </template>
										</el-input>
										<div class="present_tips">
											如果设置0，则不支持积分抵扣
											如果带%则为按成交价格的比例计算抵扣多少元
										</div>
									</el-form-item>
									<el-form-item label="允许多件累计折扣" prop="is_grand">
										<el-radio-group v-model="priceForm.is_grand">
											<el-radio :label="0">否</el-radio>
											<el-radio :label="1">是</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item label="卡券发放" prop="card">
										<div class="d-flex align-items-center cardSelect">
											<el-select v-model="priceForm.card">
												<el-option v-for="item in cardList" :key="item.value" :label="item.label"
													:value="item.value"></el-option>
											</el-select>
											<p><a href="javascript:void(0);">添加</a></p>
										</div>
										<div class="present_tips">
											卡券会在用户付款后自动发放给用户
										</div>
									</el-form-item>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 7">
							<div class="essential">
								<el-form ref="menberForm" :model="menberForm" label-width="160px">
									<div class="head_title">
										<p>会员价及设置</p>
									</div>
									<el-form-item label="是否享受会员折扣" prop="is_member">
										<el-radio-group v-model="menberForm.is_member">
											<el-radio :label="0">关闭</el-radio>
											<el-radio :label="1">开启</el-radio>
										</el-radio-group>
										<template v-if="menberForm.is_member == 1">
											<div class="mem_counts">
												<table>
													<thead>
														<tr>
															<th>规格</th>
															<th>银牌会员</th>
															<th>金牌会员</th>
															<th>plus</th>
														</tr>
													</thead>
													<colgroup>
														<col style="width: 25%" />
														<col style="width: 25%" />
														<col style="width: 25%" />
														<col style="width: 25%" />
													</colgroup>
													<tbody>
														<tr>
															<td>
																<div class="count_input">默认</div>
															</td>
															<td>
																<div class="count_input">
																	<el-input type="number" placeholder="请输入价格">
																	</el-input>
																</div>
															</td>
															<td>
																<div class="count_input">
																	<el-input type="number" placeholder="请输入价格">
																	</el-input>
																</div>
															</td>
															<td>
																<div class="count_input">
																	<el-input type="number" placeholder="请输入价格">
																	</el-input>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
												<div class="mem_tips">
													如需设置多规格会员价，请在 ->商品编辑 勾选使用规格
												</div>
											</div>
										</template>
									</el-form-item>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 8">
							<!-- 商品推广 -->
							<div class="essential">
								<el-form :rules="spreadRules" :model="spreadForm" ref="spreadForm" label-width="160px">
									<div class="head_title">
										<p>分销价及设置</p>
									</div>
									<el-form-item label="是否开启单独分销" prop="is_alone">
										<el-radio-group v-model="spreadForm.is_alone">
											<el-radio :label="0">不开启</el-radio>
											<el-radio :label="1">开启</el-radio>
										</el-radio-group>
									</el-form-item>
									<template v-if="spreadForm.is_alone == 1">
										<el-form-item label="分销类型" prop="is_retail">
											<el-radio-group v-model="spreadForm.is_retail">
												<el-radio :label="1">普通设置</el-radio>
												<el-radio :label="2">详细设置</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="分销佣金类型" prop="brokerage">
											<el-radio-group v-model="spreadForm.brokerage">
												<el-radio :label="1">百分比</el-radio>
												<el-radio :label="2">固定金额</el-radio>
											</el-radio-group>
											<div class="oneself">
												<p class="oneself_title">单独分销设置</p>
												<div class="oneself_danfx d-flex align-items-center">
													<div class="commission">
														<span>一级佣金：</span>
														<div class="inline-block">
															<el-input v-model="spreadForm.stair">
																<template slot="append">
																	{{ spreadForm.brokerage == 1 ? "%" : "元" }}
																</template>
															</el-input>
														</div>
													</div>
													<div class="commission">
														<span>二级佣金：</span>
														<div class="inline-block">
															<el-input v-model="spreadForm.second">
																<template slot="append">
																	{{ spreadForm.brokerage == 1 ? "%" : "元" }}
																</template>
															</el-input>
														</div>
													</div>
													<div class="commission">
														<span>三级佣金：</span>
														<div class="inline-block">
															<el-input v-model="spreadForm.fully">
																<template slot="append">
																	{{ spreadForm.brokerage == 1 ? "%" : "元" }}
																</template>
															</el-input>
														</div>
													</div>
												</div>
												<div class="present_tips">
													<p v-if="spreadForm.is_retail == 1">
														<a href="javascript:void(0);">分销层级</a>
														的优先级高于商品单独的分销比例，例：层级只开二级分销，那商品的单独分销比例只有二级有效
													</p>
													<p v-if="spreadForm.is_retail == 2">
														如需设置多规格分销价，请在 ->商品编辑 勾选使用规格
													</p>
												</div>
											</div>
										</el-form-item>
									</template>
									<div class="head_title">
										<p>直播设置</p>
									</div>
									<el-form-item label="是否添加到直播带货" prop="is_live">
										<el-radio-group v-model="spreadForm.is_live">
											<el-radio :label="0">关闭</el-radio>
											<el-radio :label="1">开启</el-radio>
										</el-radio-group>
									</el-form-item>
									<template v-if="spreadForm.is_live == 1">
										<el-form-item label="提成设置" prop="deduct">
											<el-radio-group v-model="spreadForm.deduct">
												<el-radio :label="1">百分比</el-radio>
												<el-radio :label="2">固定金额</el-radio>
											</el-radio-group>
										</el-form-item>
										<el-form-item label="单规格提成金额:" prop="single_price">
											<div class="inline-block">
												<span>一级佣金</span>
												<div class="inline-block">
													<el-input v-model="spreadForm.single_price">
														<template slot="append">
															{{ spreadForm.deduct == 1 ? "%" : "元" }}
														</template>
													</el-input>
												</div>
											</div>
										</el-form-item>
									</template>
								</el-form>
							</div>
						</template>
						<template v-if="goodIndex == 9">
							<div class="essential seodescribe">
								<el-form ref="seoForm" :model="seoForm" label-width="160px">
									<el-form-item label="SEO关键词" prop="keyword">
										<el-input type="textarea" maxlength="100" v-model="seoForm.keyword" show-word-limit
											resize="none"></el-input>
									</el-form-item>
									<el-form-item label="SEO描述" prop="description">
										<el-input type="textarea" maxlength="200" v-model="seoForm.description"
											show-word-limit resize="none"></el-input>
									</el-form-item>
								</el-form>
							</div>
						</template>
					</div>
				</div>
			</nav>
		</div>
		<diy-footer-button>
			<el-button type="primary" @click="preserve('ruleForm')" v-show="hasPerm(['goods.update'])">保存</el-button>
		</diy-footer-button>
		<!-- 图片选择器 -->
		<pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
		<videoSelect ref="videolist"></videoSelect>
	</div>
</template>
<script>
import Sortable from "sortablejs";
export default {
	data() {
		return {
			numberkong: null,
			dragging: null,
			errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
			id: this.$route.params.id,
			is_multiple: 0,
			editorcontent: "", //详情编辑器
			cseditor: "", //模板编辑器
			activeName: "first", //tab切换
			tabIndex: 0, //商品类型的切换
			goodIndex: 1, //商品信息的切换
			ruleForm: {
				//基本信息的表单信息
				options: [],
				goodTiltle: "", //商品名称
				subheading: "", //商品副标题
				artNo: "", //商品货号
				commodity_artNo: "",	//主商品货号
				cover_pic: "", //商品缩略图
				video: "", //商品视频
				goods_pics: [], //商品图片
				classify: "", //商品分类
				brand: "", //商品品牌
				servicecontent: "", //服务内容
				tally: "", //商品标签
				sort: "100", //商品排序
				grouptexted: [], //商品分组
				zddelivery: 0, //自动发货
				deliverycon: "", //发货内容
			},
			rules: {
				goodTiltle: [{
					required: true,
					message: "请输入商品名称",
					trigger: "blur",
				},
				{
					min: 1,
					max: 255,
					message: "长度在 0 到 255 个字符",
					trigger: "blur",
				},
				],
				classify: [{
					required: true,
					message: "请选择商品分类",
					trigger: "change",
				},],
				cover_pic: [{
					required: true,
					message: "   ",
				},],
				goods_pics: [{
					required: true,
					message: "   ",
				},],
				brand: [{
					required: true,
					message: "请选择商品品牌",
					trigger: "change",
				},],
			},
			brandOptions: [], //商品品牌
			tallyOptions: [], //商品标签
			// 价格/库存
			priceRules: {
				sellPrice: {
					required: true,
					message: "请填写售卖价格",
					trigger: "blur",
				},
				lineation: {
					required: true,
					message: "请填写市场价格",
					trigger: "blur",
				},
				repertory: {
					required: true,
					message: "请填写库存",
					trigger: "blur",
				},
			},
			priceForm: {
				specifi: [], //规格
				sellPrice: "", //售卖价格
				lineation: "", //市场价格
				costprice: "", //成本价格
				repertory: "", //库存
				unit: "件", //商品单位
				discuss: 0, //是否开启面议
				sell_num: "", //已兑换量
				purchase: "", //兑换数量
			},
			// 公共的规格参数
			cations: {
				repertory: "", //库存
				price: "", //价格,
			},
			// 商品参数
			paramForm: {
				title: "", //参数名称
				short: "", //参数简称
				paramType: 0, //类型
				partemplate: [],
			},
			// 物流
			logistics: {
				material: {
					required: true,
					message: "请选择物流支持",
					trigger: "change",
				},
				express: {
					required: true,
					message: "请选择",
					trigger: "change",
				},
			},
			phyForm: {
				material: [], //物流支持
				express: {
					unify: 0,
					price: 0,
					board: "",
				}, //快递物流
				is_express: 1, //是否显示物流
				weight: "0", //商品重量
				// volume: '', //商品体积
			},
			// 商品详情
			parForm: {
				details: "",
				sale: "", //售后保障
				packaging: "", //包装清单
			},
			parformed: {
				details: {
					required: true,
					message: "请填写商品详情",
					trigger: "change",
				},
			},
			// 优惠活动
			markeRules: {}, //
			markeForm: {
				presentExp: 0, //积分赠送
				deduction: "", //积分抵扣
				is_grand: 0, //允许多件累计折扣
			}, //优惠活动参数
			cardList: [{
				label: "",
				value: "",
			},],
			// 会员价
			menberForm: {
				is_member: 0,
			},
			// 商品推广
			spreadRules: {
				is_alone: {
					required: true,
					message: "请选择是否开启单独分销",
					trigger: "change",
				},
				is_retail: {
					required: true,
					message: "请选择分销类型",
					trigger: "change",
				},
				brokerage: {
					required: true,
					message: "请选择分销佣金类型",
					trigger: "change",
				},
				is_live: {
					required: true,
					message: "请选择是否要添加到直播带货",
					trigger: "change",
				},
				deduct: {
					required: true,
					message: "请选择提成设置",
					trigger: "change",
				},
			},
			spreadForm: {
				is_alone: 0, //是否开启单独分销
				is_retail: 1, //分销类型
				brokerage: 1, //分销佣金类型
				stair: "", //一级佣金
				second: "", //三级佣金
				fully: "", //三级佣金
				is_live: 0,
				deduct: 1,
				single_price: "", //单规格提成金额
			}, //
			seoForm: {
				keyword: "", //关键词
				description: "", //描述
			}, //seo

			paramtemplateForm: {
				title: "", //参数名称
				type: 1,
			},
			tableData: [],
			yunfeilist: [],
			boxlist: [],
			grouplist: [],
			salelist: [], //包装清单
			packaglist: [], //售后保障
			serviceconlist: [], //服务内容
			mobileActive: this.$route.query.active_id || this.$route.query.activity_id || '0',
			brandPage: 1,
			brandtotal_entry: 0,
			brandText: '',
			common_info: {}
		};
	},
	components: {
		Sortable,
	},
	created() {
		this.brandlistapi(); //商品品牌接口
		let data = {
			keys: [
				"goods.param.type.list",
				"goods.express.type.list",
				"postage_rule_list",
				"default.goods.stock",
			],
		};
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
	},
	watch: {
		common_info: {
			handler(newVal, oldVal) {
				// //console.log(newVal['default.goods.stock'])
				this.priceForm.repertory = newVal["default.goods.stock"];
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		this.goodskuapi(); //商品货号sku
		this.argumentCatlist(); //商品分类接口
		this.supportlistapi(0); //商品服务接口
		this.supportlistapi(1); //商品标签接口
		this.grouplistapi(); //商品分组
		// this.addserviceapi(5); //5:包装清单
		// this.addserviceapi(6); // 6:售后保障
		if (this.id > 0) {
			this.loopgoodsedit();
		}
	},
	methods: {
		shopfenlei(e) {
			if (e.length != 3) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "商品分类必须满足三级分类",
				});
				this.ruleForm.classify = [];
				return false;
			}
		},
		agna(val) {
			this.yunfeilist = this.common_info["postage_rule_list"];
		},
		imgbrand(val) {
			if (this.is_multiple) {
				if (this.goodIndex == 1) {
					let imgval = JSON.parse(JSON.stringify(this.ruleForm.goods_pics));
					imgval = imgval.concat(val);
					this.ruleForm.goods_pics = imgval;
				} else if (this.goodIndex == 3) {
					for (let i = 0; i < val.length; i++) {
						this.cseditor += `<p><img src="${val[i].file_url}" style="max-width:100%;"/></p>`;
					}
				} else if (this.goodIndex == 5) {
					for (let i = 0; i < val.length; i++) {
						this.editorcontent += `<p><img src="${val[i].file_url}" style="max-width:100%;"/></p>`;
					}
				}
			} else {
				this.ruleForm.cover_pic = val[0].file_url;
			}
			this.$forceUpdate();
		},
		// 查看商品详情
		loopgoodsedit() {
			this.$get(this.$apis.goodsEdit + this.id)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.vr_type = res.data.vr_type;
						if (res.data.cat_ids.length == 3) {
							this.ruleForm.classify = res.data.cat_ids;
						} else {
							this.ruleForm.classify = '';
						}
						this.ruleForm.goodTiltle = res.data.name;
						this.ruleForm.subheading = res.data.subtitle;
						this.ruleForm.artNo = res.data.sku;
						this.ruleForm.commodity_artNo = res.data.group_sku;
						this.ruleForm.cover_pic = res.data.cover_pic;
						for (let i = 0; i < res.data.goods_pic.length; i++) {
							res.data.goods_pic[i].file_url = res.data.goods_pic[i].pic_url;
						}
						this.ruleForm.goods_pics = res.data.goods_pic;

						this.ruleForm.tally = res.data.label_id;
						this.ruleForm.servicecontent = res.data.service;
						this.ruleForm.grouptexted = res.data.group,
							this.ruleForm.sort = res.data.sort;
						if (res.data.attr == null) {
							this.priceForm.specifi = [];
						} else {
							this.priceForm.specifi = res.data.attr;
						}
						this.priceForm.sellPrice = res.data.price;
						this.priceForm.lineation = res.data.purchase_price;
						this.priceForm.costprice = res.data.cost_price;
						this.priceForm.repertory = res.data.stock;
						this.priceForm.discuss = res.data.is_negotiable;
						this.priceForm.unit = res.data.unit;
						this.priceForm.sell_num = res.data.virtual_sales;
						this.priceForm.purchase = res.data.confine_count;
						this.paramForm.paramType = res.data.is_params;
						if (res.data.is_params == 3) {
							this.cseditor = res.data.params;
						} else {
							this.tableData = res.data.params;
						}
						if (res.data.delivery_type == null) {
							this.phyForm.material = [];
						} else {
							this.phyForm.material = res.data.delivery_type;
						}
						this.phyForm.express.unify = res.data.freight_type;
						this.phyForm.express.price = res.data.freight_price;
						if (res.data.freight_id > 0) {
							this.phyForm.express.board = res.data.freight_id;
						} else {
							this.phyForm.express.board = '';
						}
						this.phyForm.is_express = res.data.is_show_delivery;
						this.phyForm.weight = res.data.weight;
						// this.phyForm.volume = res.data.size;
						this.parForm.sale = Number(res.data.after_sales) == 0 ? '' : Number(res.data.after_sales);
						this.parForm.packaging = Number(res.data.packing_list) == 0 ? '' : Number(res.data
							.packing_list);
						this.editorcontent = res.data.detail;
						this.menberForm.is_member = res.data.member_discount;
						this.seoForm.keyword = res.data.seo.seo_keyword;
						this.seoForm.description = res.data.seo.seo_desc;
						if (res.data.brand_id > 0) {
							this.ruleForm.brand = res.data.brand_id;
							if (res.data.brand_name) {
								this.brandOptions.push({
									id: res.data.brand_id,
									name: res.data.brand_name
								})
							}
						} else {
							this.ruleForm.brand = '';
						}
						// // 分销
						// individual_share: 0,
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 获取分组
		grouplistapi() {
			let data = {
				page: 1,
				limit: 100,
			};
			this.$get(this.$apis.groupList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouplist = res.data.list;
					} else {
						this.common.message(this, res.message);
					}
					// this.tableloading = false;
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 获取商品货号
		goodskuapi() {
			this.$get(this.$apis.goodSku)
				.then((res) => {
					if (res.code == 200) {
						this.ruleForm.artNo = res.data.result;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 获取商品分类
		argumentCatlist() {
			this.$get(this.$apis.infoList + 'act_' + this.mobileActive)
				.then((res) => {
					if (res.code == 200) {
						this.ruleForm.options = this.getTreeData(res.data.list);
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].children.length < 1) {
					data[i].children = undefined;
				} else {
					this.getTreeData(data[i].children);
				}
			}
			return data;
		},
		// 获取品牌
		brandlistapi() {
			let data = {
				page: this.brandPage,
				limit: 10,
				search: [],
			};
			if (this.brandText) {
				data.search.push({
					key: "name",
					value: this.brandText,
					op: "search"
				});
			}
			this.$get(this.$apis.brandList, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.brandOptions = res.data.list;
						this.brandtotal_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		handclassfly(val) {
			this.brandPage = val;
			this.brandlistapi();
		},
		remoteMethoded(e) {
			this.brandText = e;
			this.brandlistapi();
		},
		templateTagChange(val) {
			if (val === false) {
				this.brandOptions = []
			} else {
				this.brandText = '';
				this.brandlistapi();
			}
		},
		// 获取商品标签
		supportlistapi(id) {
			let data = {
				search: [{
					key: "is_show",
					value: id, //0:服务内容 1:商品标签
					op: "equal",
				},],
				page: 1,
				limit: 100,
				order: [{
					key: "sort",
					value: "asc",
				},],
			};
			this.$get(this.$apis.servicelabel, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						if (id == 0) {
							this.serviceconlist = res.data.list;
						}
						if (id == 1) {
							this.tallyOptions = res.data.list;
						}
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		//获取模板
		argumentslistapi() {
			let data = {
				cat_id: this.ruleForm.classify[this.ruleForm.classify.length - 1],
				page: 1,
				limit: 100,
			};
			this.$get(this.$apis.argumentsList, data)
				.then((res) => {
					if (res.code == 200) {
						// //console.log(res)
						this.paramForm.partemplate = res.data.list;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		paramchange(val) {
			let id = val;
			// 查看商品参数详情
			this.$get(this.$apis.loopPara + id).then((res) => {
				if (res.code == 200) {
					for (let i = 0; i < res.data.param_detail.length; i++) {
						if (res.data.type == 1) {
							res.data.param_detail[i].default = res.data.param_detail[i].default.toString();
						}
						res.data.param_detail[i].checkouted = [];
					}
					this.tableData = res.data.param_detail;
					//console.log(this.tableData)
				} else {
					this.common.message(this, res.message);
				}
			}).catch((err) => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		uploadImgs() {
			this.is_multiple = 1;
			this.$refs.imglist.photoVisible = true;
		},
		headback() {
			window.history.go(-1);
		},
		// 设置商品主图
		setbannerimg(imgs) {
			this.ruleForm.cover_pic = imgs;
		},
		// 图片换位置
		handleDragStart(e, item) {
			this.dragging = item;
		},
		handleDragEnd(e, item) {
			this.dragging = null;
		},
		handleDragOver(e) {
			e.dataTransfer.dropEffect = "move";
		},
		handleDragEnter(e, item) {
			e.dataTransfer.effectAllowed = "move";
			if (item === this.dragging) {
				return;
			}
			const newItems = [...this.ruleForm.goods_pics];
			const src = newItems.indexOf(this.dragging);
			const dst = newItems.indexOf(item);

			newItems.splice(dst, 0, ...newItems.splice(src, 1));

			this.ruleForm.goods_pics = newItems;
		},
		// 图片换位置end

		// 获取售后保障和包装清单
		addserviceapi(id) {
			let data = {
				search: [{
					key: "type",
					value: id, //5:包装清单 6:售后保障
					op: "equal",
				},],
				page: 1,
				limit: 100,
				order: [{
					key: "sort",
					value: "asc",
				},],
			};
			this.$get(this.$apis.addservice, data)
				.then((res) => {
					if (res.code == 200) {
						if (id == 5) {
							this.salelist = res.data.list;
						}
						if (id == 6) {
							this.packaglist = res.data.list;
						}
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 商品类型的切换
		typeTab(value) {
			this.tabIndex = value;
		},
		// 商品信息的切换
		goodTab(value) {
			this.goodIndex = value;
			if (value == 3) {
				this.argumentslistapi();
				let text = [];
				let textradio = "";
				for (let i = 0; i < this.tableData.length; i++) {
					if (this.tableData[i].type_desc == "select") {
						textradio = "";
						for (let j = 0; j < this.tableData[i].default.length; j++) {
							if (JSON.stringify(this.tableData[i].checkout).indexOf(j) != -1) {
								textradio = this.tableData[i].default[j];
								// this.tableData[i].checkouted = textradio
								this.$set(this.tableData[i], "checkouted", textradio);
							}
						}
					}
					if (this.tableData[i].type_desc == "multi_select") {
						text = [];
						for (let j = 0; j < this.tableData[i].default.length; j++) {
							if (JSON.stringify(this.tableData[i].checkout).indexOf(j) != -1) {
								text.push(this.tableData[i].default[j]);
								// this.tableData[i].checkout = text
								this.$set(this.tableData[i], "checkouted", text);
							}
						}
					}
				}
			}
			if (value == 4) {
				this.yunfeilist = this.common_info["postage_rule_list"];
			}
		},
		mbchanges(val, scope) {
			let index = scope.$index,
				checkout = [];
			for (let i = 0; i < this.tableData.length; i++) {
				for (let j = 0; j < this.tableData[i].default.length; j++) {
					if (this.tableData[i].default[j] == val) {
						checkout.push(j);
					}
				}
			}
			this.tableData[index].checkout = checkout;
		},
		mbcheckbox(val, scope) {
			let index = scope.$index,
				checkout = [];
			for (let j = 0; j < val.length; j++) {
				if (this.tableData[index].default.indexOf(val[j]) != -1) {
					checkout.push(this.tableData[index].default.indexOf(val[j]));
				}
			}
			this.tableData[index].checkout = checkout;
			// //console.log(this.tableData)
		},
		photoVisible(num) {
			this.is_multiple = num;
			this.$refs.imglist.photoVisible = true;
		},
		delectimg(index) {
			this.ruleForm.goods_pics.splice(index, 1);
		},
		videoVisible() {
			this.$refs.videolist.videoVisible = true;
		},
		// 编辑器
		handleContentChange(val) {
			this.cseditor = val;
		},
		gooddetail(val) {
			this.editorcontent = val;
		},
		templateradio(val) {
			let that = this;
			// that.boxlist[val]=that.tableData
			that.tableData = [];
			that.paramForm.title = "";
			if (val == 1) {
				that.argumentslistapi(); //模板
				// that.tableData = that.boxlist[val+1];
			}
			if (val == 2) {
				this.$nextTick(function() {
					that.initSort();
				});
				// that.tableData = that.boxlist[val+1];
			}
			// //console.log(that.tableData)
		},
		// 删除一级规格
		delSpec(index) {
			let that = this;
			this.$confirm("你确认删除这个规格吗", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$message({
						type: "success",
						message: "删除成功!",
					});
					that.priceForm.specifi.splice(index, 1);
				})
				.catch(() => { });
		},

		// 添加规格
		appendSpec() {
			let arr = {
				stairSpec: "",
				stairSpecname: "",
			};
			if (this.priceForm.specifi.length >= 3) {
				this.$message({
					type: "warning",
					message: "最多添加3条",
				});
				return;
			}
			this.priceForm.specifi.push(arr);
		},
		// 添加参数
		addparams() {
			let obj = {
				attr_name: "",
				default: "", //参数值
			};
			this.tableData.push(obj);
		},
		delparams(scope) {
			let index = scope.$index;
			this.tableData.splice(index, 1);
		},
		initSort() {
			const el = document.querySelectorAll(
				".params_tble .el-table__body-wrapper > table > tbody"
			)[0];
			// const sortable = new Sortable(el, options);
			// 根据具体需求配置options配置项
			let that = this;
			const sortable = new Sortable(el, {
				onEnd: (evt) => {
					// 监听拖动结束事件
					// //console.log(this) // this是当前vue上下文
					// //console.log(evt.oldIndex) // 当前行的被拖拽前的顺序
					// //console.log(evt.newIndex) // 当前行的被拖拽后的顺序
					// //console.log(this.tableData)
					// 这里就可以写我们需要传给后台的逻辑代码
					// 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
					// 下面将拖拽后的顺序进行修改
					this.$nextTick(function() {
						const currRow = that.tableData.splice(evt.oldIndex, 1)[0];
						that.tableData.splice(evt.newIndex, 0, currRow);
						const newData = [];
						that.tableData.forEach((item, index) => {
							newData[index] = {
								id: item.id,
								rank: index + 1,
								title: item.title,
								fillType: item.fillType,
								value: item.value, //参数值
							};
						});
					});

					// 下面是将排序结果更新到数据库中，根据自己项目的逻辑进行实现即可。
				},
			});
		},
		// 保存
		preserve(formName) {
			let goodpic = [];
			if (this.ruleForm.goods_pics.length) {
				for (let i = 0; i < this.ruleForm.goods_pics.length; i++) {
					goodpic.push(this.ruleForm.goods_pics[i].file_url);
				}
			}
			if (!this.ruleForm.classify) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请选择商品分类",
				});
				return false;
			}
			if (!this.ruleForm.goodTiltle) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请填写商品名称",
				});
				return false;
			}
			if (!this.ruleForm.cover_pic) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请选择商品缩略图",
				});
				return false;
			}
			if (!goodpic.length) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请选择商品图片",
				});
				return false;
			}
			if (!this.ruleForm.brand) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请选择商品品牌",
				});
				return false;
			}
			if (!this.priceForm.sellPrice) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请填写售卖价格",
				});
				return false;
			}
			if (!this.priceForm.lineation) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请填写市场价格",
				});
				return false;
			}
			if (!this.priceForm.repertory) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请填写商品库存数量",
				});
				return false;
			}
			// if (!this.phyForm.material.length) {
			// 	this.$message({
			// 		type: "warning",
			// 		offset: 200,
			// 		duration: 1000,
			// 		message: "请选择物流方式",
			// 	});
			// 	return false;
			// }
			// if(this.phyForm.material.indexOf(1) != -1){
			// 	if(this.phyForm.express.unify == 0){
			// 		this.$message({
			// 			type: "warning",
			// 			offset: 200,
			// 			duration: 1000,
			// 			message: "请选择快递运费",
			// 		});
			// 		return false;
			// 	}
			// 	if(this.phyForm.express.unify == 2){
			// 		if(this.phyForm.express.board == ''){
			// 			this.$message({
			// 				type: "warning",
			// 				offset: 200,
			// 				duration: 1000,
			// 				message: "请选择运费模板",
			// 			});
			// 			return false;
			// 		}
			// 	}
			// }
			if (!this.editorcontent) {
				this.$message({
					type: "warning",
					offset: 200,
					duration: 1000,
					message: "请填写商品详情",
				});
				return false;
			}
			let data = {
				vr_type: this.tabIndex,
				cat_id: this.ruleForm.classify[this.ruleForm.classify.length - 1],
				name: this.ruleForm.goodTiltle,
				subtitle: this.ruleForm.subheading,
				sku: this.ruleForm.artNo,
				group_sku: this.ruleForm.commodity_artNo,
				cover_pic: this.ruleForm.cover_pic,
				goods_pics: goodpic,
				brand_id: this.ruleForm.brand,
				service: this.ruleForm.servicecontent,
				label_id: this.ruleForm.tally,
				group: this.ruleForm.grouptexted,
				sort: this.ruleForm.sort,
				specs: this.priceForm.specifi,
				price: this.priceForm.sellPrice,
				purchase_price: this.priceForm.lineation,
				cost_price: this.priceForm.costprice,
				stock: this.priceForm.repertory,
				is_negotiable: this.priceForm.discuss,
				unit: this.priceForm.unit,
				virtual_sales: this.priceForm.sell_num,
				confine_count: this.priceForm.purchase,
				is_params: this.paramForm.paramType,
				delivery_type: this.phyForm.material,
				freight_type: this.phyForm.express.unify,
				freight_price: this.phyForm.express.price,
				freight_id: this.phyForm.express.board,
				is_show_delivery: this.phyForm.is_express,
				weight: this.phyForm.weight,
				// size: this.phyForm.volume,
				after_sales: this.parForm.sale,
				packing_list: this.parForm.packaging,
				detail: this.editorcontent,
				member_discount: this.menberForm.is_member,
				//缺少会员折扣
				seo_keyword: this.seoForm.keyword,
				seo_desc: this.seoForm.description,
				// 分销
				individual_share: 0,
			};
			if (this.paramForm.paramType == 1) {
				if (!this.tableData.length) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请选择商品参数",
					});
					return false;
				}
				data.params = this.tableData;
			}
			if (this.paramForm.paramType == 2) {
				if (!this.tableData.length) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请填写商品参数",
					});
					return false;
				}
				data.define = this.tableData;
			}
			if (this.paramForm.paramType == 3) {
				if (!this.cseditor) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请填写商品参数",
					});
					return false;
				}
				data.text = this.cseditor;
			}
			if (this.tabIndex == 1) {
				data.auto_send = this.ruleForm.zddelivery;
				data.auto_send_info = this.ruleForm.deliverycon;
			}

			// //console.log(data);
			// return false;
			if (this.id == 0) {
				this.$post(this.$apis.addGoods, data)
					.then((res) => {
						// //console.log(res)
						if (res.code == 200) {
							this.$message({
								type: "success",
								duration: 1500,
								message: "创建成功",
								onClose: () => {
									this.$router.go(-1);
								},
							});
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// //console.log(err)
						this.common.message(this, err.message);
					});
			} else {
				this.$put(this.$apis.goodsEduted + this.id, data)
					.then((res) => {
						// //console.log(res)
						if (res.code == 200) {
							this.$message({
								type: "success",
								duration: 1500,
								message: "修改成功",
								onClose: () => {
									this.$router.go(-1);
								},
							});
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// //console.log(err)
						this.common.message(this, err.message);
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.good_addshoped header {
	margin-bottom: 0;
}

.good_addshoped nav {
	margin-top: 20px;
	overflow-y: hidden;
}

.params_input p {
	font-size: 14px;
}

.params_input /deep/ .el-input {
	width: 100%;
}

.canshu /deep/ .el-input {
	width: 100%;
}

.params_input p i {
	color: #ff2f46;
	display: inline-block;
	margin-right: 4px;
}

.attrgoods {
	width: 100%;
}

.attrgoods>div {
	width: 100%;
}

.attrgoods>div p {
	font-size: 12px;
	color: #999;
}

.el-alert {
	z-index: 5000;
}

/* 模板 */
.addtemplate {
	width: 100%;
}

.addgoodsnumber {
	/deep/.el-input {
		width: 100%;

		.el-input__inner {
			text-align: left;
		}
	}
}

.addtemplatetitle {
	width: 100%;
	margin-bottom: 20px;
	height: 46px;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 16px;
	color: #333;
	line-height: 46px;
	background: #f9f9fc;
	box-shadow: inset 0 -1px 0 #efefef;
	font-weight: bold;
}

.addtemplatecon {
	width: 100%;
	display: flex;
}

.addtemplateleft {
	float: left;
	flex: 1;
}

.addtemplateright {
	float: right;
	width: 375px;
	height: 722px;
	padding: 15px;
	background: #f4f3f7;
	margin-left: 40px;
	border-radius: 20px;
	box-sizing: border-box;
}

.addmobile {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	padding: 10px;
	box-sizing: border-box;
}

.addmobiletitle {
	width: 100%;
	font-size: 16px;
	color: #333;
	font-weight: bold;
	margin-bottom: 10px;
}

.addmobilecon {
	width: 100%;
	border-bottom: 1px solid #ededed;
	box-sizing: border-box;
	zoom: 0.7;
	max-height: 915px;
	overflow-y: auto;
}

.addtemplateconter {
	width: 100%;
	display: flex;
	min-height: 44px;
	border-top: 1px solid #ededed;
	box-sizing: border-box;
}

.addtextleft {
	width: 170px;
	padding: 9px 14px;
	align-items: center;
	background: #f5f6fa;
	box-sizing: border-box;
	border-left: 1px solid #ededed;
	display: flex;
}

.addtextright {
	flex: 1;
	padding: 9px 14px;
	box-sizing: border-box;
	border-right: 1px solid #ededed;
	word-wrap: break-word;
	min-width: 0;
}

.addtextrightext {
	width: 100%;
}

.addtextrightext span {
	margin-right: 10px;
}

.addtextrightext span:last-child {
	margin-right: 0;
}

.cseditor {
	width: 100%;
}

.params_tble /deep/ .el-table>.el-table__body-wrapper>.el-table__body {
	width: 100% !important;
}

.params_tble /deep/ .el-table>.el-table__header-wrapper>.el-table__header {
	width: 100% !important;
}

.params_title .el-input {
	width: 300px;
}

.params_title .el-input__inner {
	width: 300px;
}

.params_move {
	text-align: center;
}

.params_move i {
	font-size: 20px;
	display: inline-block;
	width: 16px;
	height: 16px;
	background: url(../../assets/images/cgview.svg) center no-repeat;
	vertical-align: -3px;
	margin-right: 2px;
}

.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.el-tabs__item {
	padding: 16px;
	padding-left: 16px;
	height: auto;
	line-height: normal;
	color: #666;
}

.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.is-active {
	font-weight: 600;
	color: var(--fontColor);
}

.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__active-bar {
	background-color: var(--fontColor);
}

.argument_foot {
	padding: 16px 0px 16px 72px;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.caozuo {
	padding: 0 10px;
	cursor: pointer;
	display: inline-block;
	color: var(--fontColor);
}

.empty_data {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.empty_data p {
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.4);
	line-height: 20px;
}

.el-table__empty-block {
	margin: 0 auto;
}

/* 公共 */
.el-radio__input.is-checked .el-radio__inner {
	background-color: #fff !important;
	width: 18px;
	height: 18px;
}

.el-radio__input .el-radio__inner {
	width: 18px;
	height: 18px;
}

.el-radio__input.is-checked .el-radio__inner::after {
	width: 8px;
	height: 8px;
	background-color: var(--fontColor);
}

.good_add {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.good_add .line {
	display: inline-block;
	padding-left: 8px;
}

nav {
	overflow: auto;
	display: flex;
	height: 100%;
	flex-direction: column;
}

.head_tab {
	background-color: #fff;
}

.head_tab .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.el-tabs__item {
	padding: 16px;
	/* padding-left: 16px; */
	height: auto;
	line-height: normal;
	color: #666;
}

.head_tab .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.is-active {
	font-weight: 600;
	color: var(--fontColor);
}

.head_tab .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__active-bar {
	background-color: var(--fontColor);
	/* min-width: 60px; */
}

.addition-type {
	/* margin-top: 20px; */
	box-sizing: border-box;
	padding: 20px 24px 24px;
	background: #fff;
	box-shadow: inset 0 -1px 0 #efefef;
	border-radius: 4px;
}

.tab-title {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.tab-title span:first-child {
	font-weight: 700;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
}

.tab-title span:first-child::before {
	content: "*";
	font-size: 18px;
	font-weight: 700;
	color: #ff2f46;
	margin-right: 5px;
}

.tab-title span:nth-child(2) {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.4);
	margin-left: 14px;
}

.tab-content {
	flex-wrap: wrap;
}

.tab_wrap {
	display: grid;
	grid-template-columns: repeat(auto-fill, 168px);
	gap: 20px;
}

.tab-item {
	padding: 12px 0 11px;
	background: #f9f9fc;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	cursor: pointer;
}

.tab-item p:first-child {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 700;
}

.tab-item p:nth-child(2) {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	margin-top: 4px;
}

.tab_wrap .tab-item.active {
	background: #eaebf6;
	border-color: var(--fontColor, #fb6638) !important;
}

.tab_wrap .tab-item.active p {
	color: var(--fontColor);
}

.addtion_wrap {
	background: #fff;
	display: flex;
	margin-top: 20px;
	box-shadow: inset 0 -1px 0 #efefef;
	border-radius: 4px;
}

.addtion_tab {
	width: 160px;
	flex-shrink: 0;
	background: #f9f9fc;
	position: sticky;
	top: 0;
	overflow-y: auto;
}

.good-tab-item {
	height: 70px;
	line-height: 70px;
	text-align: center;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
	position: relative;
}

.good-tab-item::before {
	content: "";
	transform: scaleY(0);
	opacity: 0;
	transition: all 0.4s;
}

.addtion_tab .good-tab-item.active {
	background: #fff;
	font-weight: 700;
	font-size: 16px;
	color: var(--fontColor, #fb6638);
	position: relative;
}

.addtion_tab .good-tab-item.active::before {
	content: "";
	position: absolute;
	width: 6px;
	height: 54px;
	left: 0;
	top: 8px;
	background: var(--fontColor, #fb6638);
	border-radius: 0 4px 4px 0;
	transform: scaleY(1);
	opacity: 1;
}

.addtion_container {
	flex: 1;
	padding: 30px;
	position: relative;
}

/* 基本信息 */
.good_headline {
	margin-bottom: 30px;
}

.good_headline .el-form-item .el-form-item__content .el-textarea .el-textarea__inner {
	width: 448px;
	min-height: 75px !important;
}

.addtion_container {
	/deep/.el-form-item {
		.el-form-item__content {
			.el-textarea {
				.el-textarea__inner {
					width: 448px;
					min-height: 75px !important;
					max-height: 200px;
				}

				.el-input__count {
					line-height: normal;
				}
			}
		}
	}
}

.el-form-item .el-form-item__content .el-textarea {
	width: 448px;
}

.subhead /deep/ .el-form-item .el-form-item__content .el-textarea .el-textarea__inner {
	min-height: 100px !important;
}

.el-form-item .el-form-item__content .el-textarea .el-input__count {
	line-height: 1;
}

.skeletonizecon {
	width: 80px;
	height: 80px;
	line-height: 80px;
	margin-right: 10px;
	border: 1px dashed #ededed;
	text-align: center;
	color: #dad9d9;
	float: left;
	position: relative;
	cursor: pointer;
	background: white;
}

.skeletonizecon img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.skeletonizeconed {
	min-width: 50px;
	width: auto;
	max-width: 440px;
}

.skduoxuan {
	width: 80px;
	height: 80px;
	line-height: 80px;
	margin-right: 10px;
	border: 1px dashed #ededed !important;
	text-align: center;
	color: #dad9d9;
	float: left;
	position: relative;
	cursor: pointer;
	background: white;
}

.sklist>div {
	float: left;
	width: 80px;
	height: 80px;
	margin-right: 10px;
	margin-bottom: 10px;
	border: 1px solid #ededed;
	box-sizing: border-box;
	position: relative;
	overflow: visible;
}

.sklist>div:last-child {
	margin-right: 0;
}

.sklist>div img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.sklist>div .skclose {
	position: absolute;
	top: -8px;
	right: -8px;
	font-size: 18px;
	color: var(--fontColor, #fb6638);
	cursor: pointer;
	transition: all 0.35s;
}

.sklist .setbanner {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 20px;
	overflow: hidden;
}

.sklist .setbanner strong {
	font-size: 12px;
	position: absolute;
	bottom: -20px;
	left: 0px;
	width: 100%;
	height: 20px;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
	-moz-transition: 0.3s;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	font-weight: 400;
	opacity: 0;
}

.sklist>div:hover .setbanner strong {
	bottom: 0;
	opacity: 1;
}

.skeletonize_toast {
	font-size: 12px;
	color: #999;
	padding: 4px 0;
	box-sizing: border-box;
	line-height: 1;
}

.el-form-item>.el-form-item__content>.el-input {
	width: 448px;
}

.addgoodsnumber {
	width: 448px;
}

.addgoodsnumber .el-input__inner {
	text-align: left;
}

.addgoodsbox .addgoodsnumber {
	float: left;
	width: 393px;

}

.addgoodsbox {
	/deep/.el-input-number .el-input__inner {
		border-radius: 4px 0 0 4px;
	}
}

.addgoodsbox .addgoodsnumberzl {
	float: left;
	width: 378px;
	text-align: left;
}

.addgoodsnumberzl /deep/ .el-input {
	width: 100%;
}

.addgoodsnumberzl {
	/deep/.el-input__inner {
		width: 100%;
		text-align: left;
		border-radius: 4px 0 0 4px;
	}

	/deep/.el-input-number .el-input__inner {
		width: 100%;
		text-align: left;
		border-radius: 4px 0 0 4px;
	}
}





.addgoodstext {
	float: left;
	text-align: center;
	line-height: 38px;
	padding: 0 20px;
	border: 1px solid #dcdfe6;
	border-left: 0;
	background: #f5f7fa;
	color: #909399;
	border-radius: 0 4px 4px 0;
	font-size: 14px;
	box-sizing: border-box;
}

.el-form-item>.el-form-item__content>.el-cascader {
	width: 448px;
}

.elcascaders /deep/ .el-input {
	width: 100%;
}

.goods_brand /deep/ .el-form-item .el-form-item__content .el-select {
	width: 448px;
}

.goods_brand /deep/ .el-form-item .el-form-item__content .el-select>.el-input {
	width: 100%;
}

/* end */
/* 价格/库存 */
.specification {
	background: #f7f7f7;
	padding: 20px;
	margin-bottom: 12px;
	position: relative;
	max-width: 700px;
}

.specification_error {
	border: none;
	position: absolute;
	top: -10px;
	right: -10px;
	display: none;
	cursor: pointer;
	color: #7b7b7b;
	font-size: 20px;
}

.specification:hover .specification_error {
	display: block;
}

.spec-item .el-input {
	width: 270px;
	margin-right: 12px;
}

.spec-item .el-input:last-child {
	margin-right: 0;
}

.spec-item .el-input .el-input__inner {
	width: 270px;
	height: 38px;
	line-height: 38px;
}

.spec_toast {
	color: var(--fontColor);
	cursor: pointer;
	margin-left: 10px;
}

.specification .second {
	flex-wrap: wrap;
}

.second {
	position: relative;
	padding: 24px 30px 0 28px;
	position: relative;
}

.singlescond {
	position: relative;
	margin-right: 8px;
	margin-bottom: 10px;
}

.singlescond:hover .singlescond_error {
	display: block;
}

.singlescond_error {
	position: absolute;
	width: 32px;
	padding: 0;
	display: none;
	position: absolute;
	top: -1px;
	right: -20px;
	cursor: pointer;
}

.singlescond .el-input {
	width: 270px;
}

.accretionSpect {
	position: relative;
	font-size: 14px;
	background: #fff;
	padding: 0;
}

.accretionSpect>span {
	cursor: pointer;
	margin-left: 5px;
	color: var(--fontColor);
}

.express_tem {
	margin-bottom: 20px;
}

.express_unify {
	display: inline-block;
	margin-left: 5px;
	min-width: 242px;
}

.express_radio>.el-radio-group>.el-radio {
	display: block;
}

.express_tem>.el-radio {
	line-height: 38px;
}

.express_board {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.4);
	line-height: 16px;
	margin-top: 8px;
}

.express_board a {
	padding: 0 10px;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
}

.express_board a:last-child {
	border-right: none;
}

/* end */
/* 物流 */
.matSupport .el-form-item .el-form-item__content>.el-checkbox-group {
	font-size: 14px;
}

.matSupport_way {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.4);
	line-height: 16px;
	margin-top: 8px;
}

.matSupport_way a {
	cursor: pointer;
}

.cross_text {
	line-height: 20px;
	margin-top: 8px;
	color: #999;
}

/* end */
/* 商品详情 */
.particulars>.el-form>.el-form-item>.el-form-item__content>.el-select {
	width: 220px;
}

/* end */

/* 会员价 */
.mem_counts>table>thead {
	/* margin-left: 160px;  */
	background-color: #f8f8fa;
}

.mem_counts>table {
	border-bottom: 1px solid rgb(236, 238, 239);
	box-sizing: border-box;
	width: 100%;
}

.count_input {
	text-align: center;
	padding: 10px;
}

.count_input>.el-input>.el-input__inner {
	width: 50%;
}

.mem_tips {
	color: red;
	text-align: left;
	padding-left: 134px;
	box-sizing: border-box;
}

/* 优惠活动 */
.head_title {
	height: 46px;
	line-height: 46px;
	background: #f9f9fc;
	box-shadow: inset 0 -1px 0 #efefef;
	padding: 0 20px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.head_title p {
	font-weight: 600;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
}

.present_tips {
	font-size: 12px;
	color: #999;
	padding: 4px 0;
	box-sizing: border-box;
	line-height: 2;
}

.cardSelect>.el-select {
	width: 220px;
}

.cardSelect>p {
	margin-left: 10px;
}

/* end */

.seodescribe /deep/>.el-form>.el-form-item>.el-form-item__content>.el-textarea>.el-textarea__inner {
	min-height: 100px !important;
}

.oneself {
	background: #f8f8fa;
	border-radius: 2px;
	padding: 16px;
	box-sizing: border-box;
}

.oneself_title {
	font-size: 14px;
	color: #333;
	font-weight: 500;
	line-height: 20px;
}

.oneself_danfx {
	flex-wrap: wrap;
}

.commission {
	display: flex;
	align-items: center;
	margin-top: 16px;
}

.commission .el-input__inner {
	width: 120px;
}

.oneself_danfx>div {
	margin-right: 10px;
}

.oneself_danfx>div:last-child {
	margin-right: 0;
}

.purpagebutkong {
	height: 34px;
}

.purpagebut {
	position: absolute;
	bottom: 0;
	padding: 2px 10px;
	box-sizing: border-box;
	left: 0;
	width: 100%;
	border-top: 1px solid #eee;
	background: #fff;
}

.purpagebut .btn-prev {
	float: left;
	min-width: 32px;
}

.purpagebut .btn-next {
	float: right;
	min-width: 32px;
}
</style>
